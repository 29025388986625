import React, { useEffect, useRef, useState } from 'react';

// ** 3rd party
import { FileText, FolderPlus, List, X } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import classnames from 'classnames';
import { ContentState, EditorState } from 'draft-js';
import { useParams } from 'react-router-dom';
import htmlToDraft from 'html-to-draftjs';

// ** Components
import Sidebar from '../../../components/@vuexy/sidebar';
import Spinner from '../../../components/@vuexy/spinner/Loading-spinner';
import MainContent from './MainContent';
import PrimaryButton from '../../../components/examod/buttons/primaryButton';
import SecondaryButton from '../../../components/examod/buttons/secondaryButton';
import McQuestion from './McQuestion';
import ConfirmAlert from '../../../components/examod/alert/ConfirmAlert';
import QuestionIdBadge from '../../../components/examod/customBadge/QuestionIdBadge';

// ** Domain
import {
  attachmentUploadingContinue,
  getUploadedFiles,
  questionStatuses,
  questionTypes,
} from '../../../domain/Question';
import { generalStatuses, getActiveItems } from '../../../domain/General';
import {
  defaultOptions,
  getFilledAnswers,
  hasQuestionContentError,
  mainContentTabIndex,
  mapOptions,
  mcqInputFields,
  minApprovableQuestionCount,
  scrollToIncorrectField,
  showErrorToast,
} from '../../../domain/GroupedQuestion';
import {
  UserCanCreateQuestion,
  UserCanDeleteQuestion,
} from '../../../domain/User';

// ** Utility
import { isNullOrEmpty } from '../../../utility/collectionUtil';
import { checkResult } from '../../../utility/resultHandler';
import { toastError, toastSuccess } from '../../../utility/toastUtil';

// ** Repo
import { getQuestionMeta } from '../../../data/QuestionRepository';
import {
  addGroupedQuestion,
  approveGroupedQuestion,
  deleteGroupedQuestion,
  getGroupedQuestion,
  updateGroupedQuestion,
} from '../../../data/GroupedQuestionRepository';
import { fetchCourseList } from '../../../data/CoursesRepository';

// ** Style
import '../../../assets/scss/pages/grouped-question.scss';
import { navigateToCourse } from '../../../Navigator';
import { history } from '../../../history';

const GroupedQuestion = ({
  isOpen,
  toggleSidebar,
  questionId,
  isEdit,
  isCopy,
  setIsCopy,
  singleQuestionId,
  isQuickAction,
}) => {
  // ** Default states
  const emptyState = EditorState.createEmpty();
  const defaultOptionStates = [
    { id: 0, state: emptyState },
    { id: 1, state: emptyState },
    { id: 2, state: emptyState },
    { id: 3, state: emptyState },
    { id: 4, state: emptyState },
  ];
  const canDeleteQuestion = UserCanDeleteQuestion();
  const canCreateQuestion = UserCanCreateQuestion();

  const intl = useIntl();
  let { unitId } = useParams();

  //mcq meta
  const [keywords, setKeywords] = useState([]);
  const [learningObj, setLearningObj] = useState([]);
  const [topics, setTopics] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [defaultAttributes, setDefaultAttributes] = useState([]); // default attr. for unit/course
  const [mainContentAttachmentInfo, setMainContentAttachmentInfo] = useState({
    tempId: 1,
    files: [],
    uploaded: [],
    errors: [],
    canceled: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMeta, setIsLoadingMeta] = useState(false);
  const [groupContentEditorState, setGroupContentEditorState] =
    useState(emptyState);
  const [groupContent, setGroupContent] = useState('');
  const [groupStatus, setGroupStatus] = useState('');

  const [questions, setQuestions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [showQuestionErrors, setShowQuestionErrors] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [edittedQuestionId, setEdittedQuestionId] = useState(0);
  const [showLockInfo, setShowLockInfo] = useState(false);
  const [questionHasChange, setQuestionHasChange] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [resetQuestion, setResetQuestion] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteSingleQuestion, setShowDeleteSingleQuestion] =
    useState(false);
  const [groupedQuestionId, setGroupedQuestionId] = useState(0);
  const [deletedQuestionId, setDeletedQuestionId] = useState(0);
  const [tabChanged, setTabChanged] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [activeTabQuestionId, setActiveTabQuestionId] = useState(0);
  const [canChangeQuestionCount, setCanChangeQuestionCount] = useState(true);
  const [IsstructuralSettings, setIsStructuralSettings] = useState(false);
  const [loForce, setLoForce] = useState(false);

  const needCourseSelection = isQuickAction && !selectedCourse;

  const fieldRefs = useRef([]);

  // ** Hooks
  useEffect(() => {
    if (isOpen) {
      handleGroupedQuestionInfo(unitId);
      getCourseList();
    }
  }, [isOpen]);

  useEffect(() => {
    setShowQuestionErrors(false);
    setTabChanged(true);
  }, [activeTab]);

  useEffect(() => {
    if (activeTab !== 0 && questions) {
      setActiveTabQuestionId(questions?.[activeTab - 1]?.id);
    }
  }, [activeTab, questions]);

  const defaultGroupedMcq = {
    type: 'MULTIPLE_CHOICE',
    topic: null,
    questionRoot: '',
    rootEditorState: emptyState,
    options: defaultOptions,
    optionStates: defaultOptionStates,
    correctOption: null,
    attachments: {
      files: [],
      uploadedFiles: [],
      errors: [],
      canceled: [],
      tempId: 1,
    },
    attributes: defaultAttributes,
    fields: [],
    keywords: [],
    learningObj: null,
    source: '',
    hasErrors: {
      topic: true,
      questionRoot: true,
      options: true,
      fields: true,
      correctOption: true,
      attachments: false,
      source: false,
      learningObj: loForce,
    },
  };

  const defaultGroupedMcqNoError = {
    ...defaultGroupedMcq,
    hasErrors: {
      topic: false,
      questionRoot: false,
      options: false,
      fields: false,
      correctOption: false,
      attachments: false,
      source: false,
      learningObj: false,
    },
  };
  const currentQuestionOrder = activeTab - 1;
  const handleGroupedQuestionInfo = async (unitId) => {
    setIsLoading(true);
    if (!needCourseSelection) {
      const meta = await getCreateQuestionMeta(unitId);
      if (isEdit || isCopy) {
        await getQuestions(meta?.data);
      }
    }
    setIsLoading(false);
  };

  const getCourseList = async () => {
    if (isQuickAction) {
      const result = await fetchCourseList();
      if (checkResult(result)) {
        const activeCourses = result?.data?.filter(
          (course) => course.status === generalStatuses.ACTIVE
        );
        setCourseList(activeCourses);
      }
    }
  };

  const getCreateQuestionMeta = async (unitId) => {
    setIsLoadingMeta(isQuickAction ? true : false);
    let result = await getQuestionMeta(
      isEdit ? 'UPDATE' : 'CREATE',
      unitId,
      'MULTIPLE_CHOICE'
    );
    if (checkResult(result)) {
      setTopics(
        mapOptions(
          result.data.topics.sort(
            (firstItem, secondItem) => firstItem.order - secondItem.order
          )
        )
      );
      setKeywords(mapOptions(result.data.keywords));
      let activeObjectives = getActiveItems(result.data.learningObjectives);
      setLoForce(result.data.loForce);
      setLearningObj(
        mapOptions(
          activeObjectives.sort(
            (firstItem, secondItem) => firstItem.order - secondItem.order
          )
        )
      );

      handleAttributeMeta(result.data.attributes);
      setIsLoadingMeta(false);
      return result;
    }
  };

  const getQuestions = async (meta) => {
    const result = await getGroupedQuestion(questionId);
    if (checkResult(result)) {
      prepareStates(result.data, meta);
    }
    setQuestionHasChange(false);
  };

  const onLockQuestion = async () => {
    setIsLoading(true);
    const approvedQestionId = edittedQuestionId || questionId;
    const result = await approveGroupedQuestion(approvedQestionId);
    if (checkResult(result)) {
      setShowLockInfo(false);
      toggleSidebar('toggle', 'updateQuestions');
      setShowDiscard(false);
      toastSuccess(intl.formatMessage({ id: 'questionAdd.successLock' }));
    }
    setIsLoading(false);
  };

  const addQuestion = async () => {
    setIsLoading(true);
    const body = prepareQuestionAdding();
    const result = await addGroupedQuestion(body);
    if (checkResult(result)) {
      setEdittedQuestionId(result.data.id);
      if (isQuickAction) {
        toggleSidebar(true);
        navigateToCourse(result.data.unitId, 'questions');
        history.go(0); //reloads page
      } else {
        toggleSidebar(false, 'update');
      }
      setShowDiscard(false);
      if (setIsCopy) {
        setIsCopy(false);
      }
      toastSuccess(intl.formatMessage({ id: 'questionAdd.success' }));
    }
    setGroupStatus('');
    setIsLoading(false);
  };

  const updateQuestion = async () => {
    setIsLoading(true);
    const body = prepareQuestionAdding(true);
    const qId = edittedQuestionId || questionId;
    const result = await updateGroupedQuestion(qId, body);
    if (checkResult(result)) {
      toggleSidebar('toggle', 'update');
      setShowDiscard(false);
      toastSuccess(intl.formatMessage({ id: 'questionEdit.editSuccess' }));
    }
    setIsLoading(false);
  };

  const deleteQuestion = async () => {
    setIsLoading(true);
    const qId = edittedQuestionId || questionId;
    let result = await deleteGroupedQuestion(qId);
    if (checkResult(result)) {
      toastSuccess(intl.formatMessage({ id: 'questions.deleteSuccess' }));
      toggleSidebar('toggle', 'update');
      setShowDiscard(false);
    }
    setIsLoading(false);
  };

  const checkLoAndShowError = (checkForAll) => {
    if (loForce) {
      const loMissingQuestionIndexes = questions
        .map((q, i) => (q.hasErrors?.learningObj ? i + 1 : null))
        .filter((q) => q);
      const needCheckAllQuestions =
        activeTab === mainContentTabIndex ||
        !currentQuestionHasError(questions[currentQuestionOrder].hasErrors) ||
        checkForAll;
      if (needCheckAllQuestions && !isNullOrEmpty(loMissingQuestionIndexes)) {
        toastError(
          intl.formatMessage(
            { id: 'groupedQuestion.missingLoError' },
            { indexes: loMissingQuestionIndexes.join(', ') }
          )
        );
        return !isNullOrEmpty(loMissingQuestionIndexes);
      }
    }
  };

  const getAttributeOptions = (attributeId, attributeMeta) => {
    const attribute = attributeMeta?.find((attr) => attr.id == attributeId);
    return attribute?.options;
  };

  const prepareStates = (data, meta) => {
    setCanChangeQuestionCount(isCopy || data.questions[0].canBeDeleted);
    setGroupedQuestionId(data.id);
    setGroupContent(data.content);
    setGroupContentEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(data.content))
      )
    );
    setGroupStatus(data.status);
    const mainAttachmentInfo = getAttachmetFileStates(data.attachments);
    setMainContentAttachmentInfo((prev) => ({
      ...prev,
      tempId: mainAttachmentInfo.tempId,
      files: mainAttachmentInfo.files,
      uploaded: mainAttachmentInfo.files,
    }));
    const allQuestions = data.questions.sort((a, b) =>
      a.groupOrder > b.groupOrder ? 1 : -1
    );
    const questionList = allQuestions.map((question, index) => {
      const topic = {
        value: question.topic.id,
        label: question.topic.name,
      };
      const root = question.root;
      const rootState = EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(question.root))
      );
      let options = question.answers?.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      options = options.map((opt) => ({
        ...opt,
        onlyImageAnswer: !isNullOrEmpty(opt.links),
      }));
      const answerStates = question.answers.map((ans) => ({
        id: ans.id,
        state: EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(ans.content))
        ),
      }));
      const correctOption = getCorrectAnswer(question.answers);
      const revisedFiles = getAttachmetFileStates(question.attachments);
      const attributes = question.attributeOptions.map(
        (option) => option.attribute
      );
      const newAttributes = attributes.map((item) => ({
        value: item.id,
        label: item.name,
        optionData:
          item.options || getAttributeOptions(item.id, meta?.attributs),
        description: item.description,
      }));
      const fields = question.attributeOptions.map((element) => ({
        value: element.attributeOption.id,
        label: element.attributeOption.name,
        fieldId: element.attribute.id,
      }));
      const source = question.questionSource;
      const keyword = mapOptions(question.keywords);
      let objective = [];
      if (!question.learningObjective.default) {
        objective = {
          value: question.learningObjective.id,
          label: question.learningObjective.name,
        };
      }
      handleInitTab(question, index);
      const defaultGroupedMcqNoErrorTemp = JSON.parse(
        JSON.stringify(defaultGroupedMcqNoError)
      );
      if (meta?.loForce && isNullOrEmpty(objective)) {
        defaultGroupedMcqNoErrorTemp.hasErrors.learningObj = true;
      }
      return {
        ...defaultGroupedMcqNoErrorTemp,
        tempQId: index,
        id: question.id,
        topic: topic,
        questionRoot: root,
        rootEditorState: rootState,
        options: options,
        optionStates: answerStates,
        correctOption: correctOption,
        attachments: {
          uploadedFiles: revisedFiles.files,
          files: revisedFiles.files,
          tempId: revisedFiles.tempId,
          canceled: [],
          errors: [],
        },
        attributes: newAttributes,
        fields: fields,
        keywords: keyword,
        learningObj: objective,
        source: source,
      };
    });
    setQuestions(questionList);
    setQuestionIndex(questionList.length);
  };
  const handleInitTab = (question, index) => {
    if (isEdit && singleQuestionId === question.id && !isCopy) {
      setActiveTab(index + 1);
      setQuestionHasChange(false);
    }
  };

  const getAttachmetFileStates = (uploadedFiles) => {
    let revisedAttachments = [];
    let tempId = 1;
    if (!isNullOrEmpty(uploadedFiles)) {
      revisedAttachments = uploadedFiles.map((attch) => ({
        id: attch.id,
        tempId: tempId++,
        name: attch.name,
        type: `${attch.fileType}/${attch.extension}`.toLowerCase(),
        size: attch.size,
        generalType: attch.fileType,
      }));
    }
    return { files: revisedAttachments, tempId: tempId };
  };

  const getCorrectAnswer = (items) => {
    let [correct] = items.filter((item) => item.correctAnswer);
    return correct.id;
  };

  const prepareQuestionAdding = (isEdit) => {
    const questionList = questions.map((question, index) => {
      const root = question.questionRoot;
      const type = question.type;
      const defaultTimeValue = 0; // suggested duration, will be updated
      const answers = getFilledAnswers(question);
      const attributeOptions = question.fields
        ? question.fields.map((field) => ({
            attributeId: field?.fieldId,
            attributeOptionId: field?.value,
          }))
        : [];
      const keywords = question.keywords
        ? question.keywords.map((keyword) => ({
            id: keyword.value,
            name: keyword.label,
          }))
        : [];
      const learningObjId = question.learningObj
        ? question.learningObj.value
        : null;
      const topicId = question?.topic?.value;
      const source = question.source;
      const attachmentIds = getUploadedFiles(
        question?.attachments?.uploadedFiles,
        question?.attachments?.canceled,
        true
      );
      const isDuplicateAttachment =
        (isCopy && !isNullOrEmpty(attachmentIds)) || false;

      return {
        ...(isEdit && { id: question.id }),
        root: root,
        unitId: selectedCourse?.value || unitId,
        questionType: type,
        suggestedTime: defaultTimeValue,
        answers: answers,
        attributeOptions: attributeOptions,
        keywords: keywords,
        learningObjectiveId: learningObjId,
        topicId: topicId,
        questionSource: source,
        groupOrder: index,
        attachmentIds: attachmentIds,
        duplicateExistingAttachments: isDuplicateAttachment,
      };
    });
    const mainContentAttachmentIds = getUploadedFiles(
      mainContentAttachmentInfo?.uploaded,
      mainContentAttachmentInfo?.canceled,
      true
    );
    const isDuplicateMainAttachment =
      (isCopy && !isNullOrEmpty(mainContentAttachmentIds)) || false;
    let body = {
      content: groupContent,
      attachmentIds: mainContentAttachmentIds,
      unitId: selectedCourse?.value || unitId,
      questions: questionList,
      duplicateExistingAttachments: isDuplicateMainAttachment,
    };
    return body;
  };

  const handleAttributeMeta = (attributes) => {
    const defaultAttributes = [];
    const allAttributes = [];
    attributes.forEach((attr) => {
      const isAttributeActive = attr.status === generalStatuses.ACTIVE;
      if (isAttributeActive && !isNullOrEmpty(attr)) {
        const attributeOption = {
          value: attr.id,
          label: attr.name,
          optionData: attr.options,
          description: attr.description,
        };
        allAttributes.push(attributeOption);
        if (!isNullOrEmpty(attr.units)) {
          defaultAttributes.push(attributeOption);
        }
      }
    });
    setAttributes(allAttributes);
    setDefaultAttributes(defaultAttributes);
  };

  const onAddQuestion = () => {
    const tempId = isNullOrEmpty(questions)
      ? 0
      : questions[questions.length - 1].tempQId + 1;
    const newQuestion = {
      tempQId: tempId,
      ...defaultGroupedMcq,
    };
    setQuestions((prev) => [...prev, newQuestion]);
    setQuestionIndex((prev) => prev + 1);
    setActiveTab(questions.length + 1);
    setQuestionHasChange(true);
  };

  const onDeleteQuestion = () => {
    const tempQuestions = questions.filter(
      (q, i) => q.tempQId !== deletedQuestionId
    );
    if (activeTab === 1 && !isNullOrEmpty(tempQuestions)) {
      setActiveTab(1);
    } else {
      setActiveTab((prev) => prev - 1);
    }
    setQuestions([...tempQuestions]);
    setShowQuestionErrors(false);
    setResetQuestion(true);
  };

  const setQuestionFields = (field, value) => {
    const newQuestions = questions;
    if (field === mcqInputFields.attachments) {
      for (const attchKey in value) {
        if (Object.hasOwnProperty.call(value, attchKey)) {
          const attachmentInfo = value[attchKey];
          newQuestions[currentQuestionOrder][field][attchKey] = attachmentInfo;
        }
      }
    } else {
      newQuestions[currentQuestionOrder][field] = value;
    }
    setTabChanged(false);
    if (!tabChanged) {
      setQuestionHasChange(true);
    }
    setQuestions([...newQuestions]);
  };

  const onCreateGroupedQuestion = () => {
    if (!hasGroupedQuestionError()) {
      if ((isEdit || edittedQuestionId) && !isCopy) {
        updateQuestion();
      } else {
        addQuestion();
      }
      setQuestionHasChange(false);
    }
  };

  const handleQuestionFieldError = (hasError, field) => {
    if (!isNullOrEmpty(questions)) {
      const tempQuestions = questions;
      if (tempQuestions[currentQuestionOrder].hasErrors[field] === hasError) {
        return;
      }
      tempQuestions[currentQuestionOrder].hasErrors[field] = hasError;
      setQuestions([...tempQuestions]);
    }
  };

  const hasMainContentAttachmentError = (showToast) => {
    const isAttachmentUploading = attachmentUploadingContinue(
      mainContentAttachmentInfo.files,
      mainContentAttachmentInfo.uploaded,
      mainContentAttachmentInfo.errors,
      mainContentAttachmentInfo.canceled
    );
    const hasError =
      isAttachmentUploading || !isNullOrEmpty(mainContentAttachmentInfo.errors);
    if (hasError && showToast) {
      toastError(intl.formatMessage({ id: 'questionAdd.attachmentError' }));
    }
    return hasError;
  };

  const mainContentInputsHasError = () => {
    return hasQuestionContentError(groupContent) || needCourseSelection;
  };

  const hasGroupedQuestionError = () => {
    if (mainContentInputsHasError()) {
      toastError(intl.formatMessage({ id: 'questionAdd.warning' }));
      setShowQuestionErrors(true);
      return true;
    } else if (hasMainContentAttachmentError()) {
      toastError(intl.formatMessage({ id: 'questionAdd.attachmentError' }));
      setShowQuestionErrors(true);
      return true;
    } else if (questionsHasError()) {
      showFieldsError();
      setShowQuestionErrors(true);
      return true;
    } else {
      setShowQuestionErrors(false);
      return false;
    }
  };

  // if question has error returns first encountered incorrect field name
  const questionsHasError = (getField) => {
    for (let i = 0; i < questions.length; i++) {
      const questionErrors = questions[i]?.hasErrors;
      const hasCurrentQuestionError = currentQuestionHasError(
        questionErrors,
        getField
      );
      if (hasCurrentQuestionError) {
        return hasCurrentQuestionError;
      }
    }
    return false;
  };

  const currentQuestionHasError = (errorsMap, getField) => {
    for (const type in errorsMap) {
      if (Object.hasOwnProperty.call(errorsMap, type)) {
        const hasError = errorsMap[type];
        if (hasError) {
          return getField ? type : true;
        }
      }
    }
  };

  const showFieldsError = () => {
    if (questionsHasError() && questions[currentQuestionOrder]) {
      const incorrectField = questionsHasError(true);
      const hasAttachmentError = incorrectField === mcqInputFields.attachments;
      showErrorToast(questions[currentQuestionOrder], intl, hasAttachmentError);
      scrollToIncorrectField(fieldRefs, incorrectField);
    }
  };

  const resetGroup = () => {
    setGroupContentEditorState(emptyState);
    setGroupContent('');
    setQuestions([]);
    setActiveTab(mainContentTabIndex);
    setShowQuestionErrors(false);
    setEdittedQuestionId(0);
    setQuestionHasChange(false);
    setIsLoading(true);
    resetCourseRelatedFields();
  };

  const resetCourseRelatedFields = () => {
    setSelectedCourse(null);
    setKeywords([]);
    setLearningObj([]);
    setTopics([]);
    setAttributes([]);
    setDefaultAttributes([]);
    setIsLoadingMeta(true);
  };

  const resetCurrentTab = () => {
    if (activeTab === mainContentTabIndex) {
      setGroupContentEditorState(emptyState);
      setGroupContent('');
    } else {
      const tempQuestions = questions;
      tempQuestions[currentQuestionOrder] = {
        tempQId: tempQuestions[currentQuestionOrder].tempQId,
        ...defaultGroupedMcq,
      };
      setQuestions([...tempQuestions]);
      setResetQuestion(true);
    }
  };

  const onDiscardConfirmation = () => {
    toggleSidebar('toggle');
    setShowDiscard(false);
  };

  const onDiscardCancel = () => {
    setShowDiscard(false);
  };

  const showLockInfoConfirmation = () => {
    if (questions.length < minApprovableQuestionCount) {
      toastError(intl.formatMessage({ id: 'groupedQuestion.minApproved' }));
    } else if (questionHasChange) {
      toastError(intl.formatMessage({ id: 'groupedQuestion.saveToApprove' }));
    } else {
      if (!checkLoAndShowError(true)) {
        setShowLockInfo(true);
      }
    }
  };

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const cancelQuestion = () => {
    if (questionHasChange) {
      setShowDiscard(true);
    } else {
      toggleSidebar('toggle');
      setShowDiscard(false);
    }
  };

  const saveConfirmationToggle = () => {
    if (!checkLoAndShowError()) {
      if (!hasGroupedQuestionError()) {
        setShowSaveConfirmation((prev) => !prev);
      }
    }
  };

  const isShownConfirmAlert = () => {
    return (
      showSaveConfirmation ||
      showLockInfo ||
      showDeleteConfirmation ||
      showDeleteSingleQuestion
    );
  };

  const confirmAlertMessage = () => {
    if (showLockInfo) {
      return (
        <FormattedMessage id={'groupedQuestion.lockConfirmationMessage'} />
      );
    }
    if (showSaveConfirmation) {
      return (
        <FormattedMessage id={'groupedQuestion.saveConfirmationMessage'} />
      );
    }
    if (showDeleteConfirmation) {
      return (
        <FormattedMessage id={'questions.deleteGroupConfirmationMessage'} />
      );
    }
    if (showDeleteSingleQuestion) {
      return <FormattedMessage id={'questions.deleteSingleConfirmation'} />;
    }
  };

  const confirmButtonText = () => {
    if (showDeleteSingleQuestion) {
      return <FormattedMessage id={'questions.deleteSingleConfirm'} />;
    } else {
      return <FormattedMessage id={'groupedQuestion.continue'} />;
    }
  };
  const confirmAlertTitle = () => {
    if (showDeleteSingleQuestion) {
      return <FormattedMessage id={'questions.deleteSingleTitle'} />;
    } else {
      return (
        <FormattedMessage id={'groupedQuestion.confirmationContinueTitle'} />
      );
    }
  };

  const onConfirmAlert = () => {
    if (showLockInfo) {
      onLockQuestion();
    } else if (showSaveConfirmation) {
      onCreateGroupedQuestion();
    } else if (showDeleteConfirmation) {
      deleteQuestion();
    } else if (showDeleteSingleQuestion) {
      onDeleteQuestion();
    }
    onCancelConfirmAlerts();
  };

  const onCancelConfirmAlerts = () => {
    setShowSaveConfirmation(false);
    setShowLockInfo(false);
    setShowDeleteConfirmation(false);
    setShowDeleteSingleQuestion(false);
  };

  const handleDeleteSingleQuestion = (event, tempQId) => {
    event.stopPropagation();
    setShowDeleteSingleQuestion(true);
    setDeletedQuestionId(tempQId);
  };

  return (
    <>
      <Sidebar
        size="lg"
        open={isOpen}
        headerLeftComponent={
          <span>
            {activeTab !== 0 ? (
              <QuestionIdBadge
                idText={activeTabQuestionId}
                badgeClassName="question-id-badge"
                spanClassName="modal-header-question-divider"
                spanText={'|'}
              />
            ) : (
              ''
            )}
          </span>
        }
        title={
          isCopy
            ? intl.formatMessage({ id: 'groupedQuestion.duplicate' })
            : isEdit || edittedQuestionId
            ? intl.formatMessage({
                id: 'groupedQuestion.edit',
              })
            : intl.formatMessage({
                id: 'groupedQuestion.title',
              })
        }
        headerClassName="bg-info rounded-0 mb-1 question-id-badge-area"
        contentClassName="grouped-question-sidebar pt-0"
        toggleSidebar={cancelQuestion}
        onClosed={resetGroup}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {' '}
            <div className="group-question-steps">
              <button
                className={classnames(
                  'grouped-question-upper-btn',
                  'grouped-question-main-content-btn',
                  {
                    'grouped-tab-is-active': activeTab === mainContentTabIndex,
                  }
                )}
                onClick={() => {
                  if (
                    currentQuestionHasError(
                      questions[currentQuestionOrder]?.hasErrors
                    )
                  ) {
                    setShowQuestionErrors(true);
                    showFieldsError();
                  } else {
                    setActiveTab(mainContentTabIndex);
                  }
                }}
              >
                <FileText
                  className="grouped-question-upper-btn-icon"
                  size={18}
                />
                <FormattedMessage id="groupedQuestion.mainContent" />
              </button>
              {questions.map((question, index) => (
                <button
                  key={index}
                  className={classnames(
                    'grouped-question-upper-btn',
                    'grouped-question-individual-question-btn',
                    {
                      'grouped-tab-is-active': activeTab === index + 1,
                    }
                  )}
                  onClick={() => {
                    if (
                      activeTab === mainContentTabIndex &&
                      (mainContentInputsHasError() ||
                        hasMainContentAttachmentError(true))
                    ) {
                      setShowQuestionErrors(true);
                    } else if (
                      currentQuestionHasError(
                        questions[currentQuestionOrder]?.hasErrors
                      )
                    ) {
                      setShowQuestionErrors(true);
                      showFieldsError();
                    } else {
                      setActiveTab(index + 1);
                    }
                  }}
                >
                  <List className="grouped-question-upper-btn-icon" size={18} />
                  <FormattedMessage
                    id="groupedQuestion.questionNumber"
                    values={{ number: index + 1 }}
                  />
                  {activeTab === index + 1 && canChangeQuestionCount ? (
                    <X
                      className="delete-individual-question-btn"
                      onClick={(event) =>
                        handleDeleteSingleQuestion(event, question.tempQId)
                      }
                    />
                  ) : null}
                </button>
              ))}
              {questions.length < 10 &&
              canChangeQuestionCount &&
              canCreateQuestion ? (
                <div className="grouped-question-add-question-container">
                  <div className="add-question-btn-seperator" />
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="grouped-question-upper-btn grouped-question-add-question-btn"
                      color="none"
                      onClick={() => {
                        if (!checkLoAndShowError()) {
                          hasGroupedQuestionError();
                        }
                      }}
                    >
                      <FolderPlus
                        className="grouped-question-upper-btn-icon folder-plus"
                        size={18}
                      />
                      <FormattedMessage id="groupedQuestion.addQuestion" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        key={questionTypes.MULTIPLE_CHOICE}
                        disabled={
                          questionsHasError() ||
                          mainContentInputsHasError() ||
                          hasMainContentAttachmentError()
                        }
                        className={'width-250-px'}
                        action
                        onClick={() => {
                          onAddQuestion(questionTypes.MULTIPLE_CHOICE);
                        }}
                      >
                        <FormattedMessage id="questions.mcq" />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              ) : null}
            </div>
            <div className="grouped-question-wrapper">
              {activeTab === mainContentTabIndex ? (
                <MainContent
                  setGroupContentEditorState={setGroupContentEditorState}
                  groupContentEditorState={groupContentEditorState}
                  groupContent={groupContent}
                  setGroupContent={setGroupContent}
                  mainContentAttachmentInfo={mainContentAttachmentInfo}
                  setMainContentAttachmentInfo={setMainContentAttachmentInfo}
                  showQuestionErrors={showQuestionErrors}
                  setQuestionHasChange={setQuestionHasChange}
                  questionHasChange={questionHasChange}
                  groupId={groupedQuestionId}
                  isQuickAction={isQuickAction}
                  setSelectedCourse={setSelectedCourse}
                  selectedCourse={selectedCourse}
                  courseList={courseList}
                  getCreateQuestionMeta={getCreateQuestionMeta}
                  questionAdded={!isNullOrEmpty(questions)}
                />
              ) : !isNullOrEmpty(questions) ? (
                <McQuestion
                  setQuestionFields={setQuestionFields}
                  groupedQuestions={questions}
                  activeTab={activeTab}
                  handleQuestionFieldError={handleQuestionFieldError}
                  setShowQuestionErrors={setShowQuestionErrors}
                  showQuestionErrors={showQuestionErrors}
                  meta={{
                    topics: topics,
                    attributes: attributes,
                    defaultAttributes: defaultAttributes,
                    keywords: keywords,
                    learningObj: learningObj,
                  }}
                  unitId={selectedCourse?.value || unitId}
                  fieldRefs={fieldRefs}
                  resetQuestion={resetQuestion}
                  setResetQuestion={setResetQuestion}
                  isLoadingMeta={isLoadingMeta}
                  loForce={loForce}
                />
              ) : null}
              {!isLoadingMeta ? (
                <div>
                  {(isEdit || edittedQuestionId) && !isCopy ? (
                    <div className="edit-grouped-questions-buttons action-buttons">
                      <PrimaryButton
                        disabled={questions.length < 1}
                        onClick={() => {
                          if (!checkLoAndShowError()) {
                            onCreateGroupedQuestion();
                          }
                        }}
                        textId="groupedQuestion.editSave"
                        className="edit-question-save"
                      />
                      <SecondaryButton
                        className={classnames({
                          'approve-group-disabled':
                            questions.length < minApprovableQuestionCount ||
                            questionHasChange,
                        })}
                        textId="questionAdd.lock"
                        onClick={showLockInfoConfirmation}
                        disabled={groupStatus === questionStatuses.APPROVED}
                      />
                      {canDeleteQuestion ? (
                        <SecondaryButton
                          onClick={() => {
                            if (activeTab === mainContentTabIndex) {
                              handleDeleteConfirmation();
                            } else {
                              resetCurrentTab();
                            }
                          }}
                          textId={
                            activeTab === mainContentTabIndex
                              ? 'questionEdit.delete'
                              : 'buttons.reset'
                          }
                          color="primary"
                          className="btn-danger-ba"
                        />
                      ) : null}
                      <SecondaryButton
                        onClick={cancelQuestion}
                        textId="buttons.cancel"
                      />
                    </div>
                  ) : (
                    <div className="add-grouped-question-buttons action-buttons">
                      <PrimaryButton
                        disabled={questions.length < 1}
                        textId="groupedQuestion.save"
                        onClick={saveConfirmationToggle}
                      />
                      <SecondaryButton
                        onClick={resetCurrentTab}
                        color="primary"
                        className="btn-danger-ba"
                        textId="buttons.reset"
                      />
                      <SecondaryButton
                        onClick={cancelQuestion}
                        className="btn-outline-ba"
                        textId="buttons.cancel"
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </>
        )}
      </Sidebar>
      <ConfirmAlert
        isShown={isShownConfirmAlert()}
        title={confirmAlertTitle()}
        message={confirmAlertMessage()}
        confirmBtnText={confirmButtonText()}
        cancelBtnText={<FormattedMessage id={'groupedQuestion.cancel'} />}
        onConfirm={onConfirmAlert}
        onCancel={onCancelConfirmAlerts}
        confirmBtnCssClass="btn-filled-ba"
      />
      <ConfirmAlert
        isShown={showDiscard}
        title={<FormattedMessage id={'questionAdd.discardConfirmationTitle'} />}
        message={
          <FormattedMessage id={'groupedQuestion.discardConfirmationMessage'} />
        }
        confirmBtnText={
          <FormattedMessage
            id={'questionAdd.discardConfirmationConfirmButton'}
          />
        }
        cancelBtnText={
          <FormattedMessage
            id={'questionAdd.discardConfirmationCancelButton'}
          />
        }
        onConfirm={onDiscardConfirmation}
        onCancel={onDiscardCancel}
      />
      <ConfirmAlert
        isShown={IsstructuralSettings}
        title={<FormattedMessage id={'assessmentAdd.discardChanges'} />}
        message={
          <FormattedMessage
            id={'assessmentAdd.cancelCreateAssessmentPopupMessage'}
          />
        }
        confirmBtnText={<FormattedMessage id={'assessmentAdd.discard'} />}
        cancelBtnText={<FormattedMessage id={'assessmentAdd.cancel'} />}
        onConfirm={() => addQuestion()}
        onCancel={() => setIsStructuralSettings(false)}
      />
    </>
  );
};

export default GroupedQuestion;
